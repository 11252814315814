import React, { useState, useEffect } from "react";
import {
  ReactiveBase,
  ReactiveComponent,
  DataSearch,
  MultiDropdownList,
  SelectedFilters,
  ReactiveList,
  ResultCard,
  RangeSlider,
  DynamicRangeSlider,
} from "@appbaseio/reactivesearch";
import { Row, Col, Card, Divider, Switch } from "antd";
import "antd/dist/antd.min.css";
import * as cookieUtils from "../assets/cookie-utils.js";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styles from "./Search.module.css";
import config from "../config/config.js";
import * as constants from "../assets/constants.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as query from "./es-queries/elasticQueries.js";
import moreIcon from "../assets/imgs/arrowDown.png";
import lessIcon from "../assets/imgs/arrowUp.png";
import { Box } from "@material-ui/core";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import Button from "@mui/material/Button";
import ESDateRange from "./es-date-range/ESDateRange.js";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("en-US");
dayjs.tz.setDefault("America/New_York");

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#495057",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#495057",
    fontSize: "13px",
  },
}));

export default function ElasticSearch() {
  let initialUserInfo = {
    userType: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    status: "",
    role: "",
    office: "",
    userKey: 0,
    clientKey: 0,
    reportLevel: "",
    reportEntities: "",
  };
  let defaultFromDate;
  let defaultToDate;
  let wildCardQuery, exactMatchQuery, initialQuery;
  let componentIdsSync = [
    "CallDisposition",
    "AgentName",
    "Account",
    "Opportunity",
    "Stage",
    "KPI",
    "CampaignName",
    "CallSource",
    "Speaker",
    "ParticipantName",
    "HostName",
    "Topic",
    "Duration",
    "Talktime",
    "SpeakPercent",
    "Search",
    "DateRange",
    "ClientName",
    "RegionName",
    "TeamName",
    "OfficeName",
  ];

  const [userInfo, setUserInfo] = React.useState(initialUserInfo);
  const [openDetails, setOpenDetails] = useState(false);
  const [activeId, setActiveid] = useState("");
  const [conversationRedirect] = React.useState(" ");
  const [endpoint, setEndpoint] = useState("");
  const [indexEnv, setIndexEnv] = useState("");
  const [statsCount, setStatsCount] = useState(0);
  const [selectedFromDate, setSelectedFromDate] = React.useState(defaultFromDate);
  const [selectedToDate, setSelectedToDate] = React.useState(defaultToDate);
  const [refreshDate, setRefreshDate] = React.useState(false);
  const [changeQuery, setChangeQuery] = useState(false);
  const [filterBool, setFilterBool] = useState(false);
  const [userInput, setUserInput] = useState("");
  const serverURL = config.PCA_SERVER_URL;
  const indexName = indexEnv + "*";

  useEffect(() => {
    (async () => {
      try {
        let activeUser = await cookieUtils.fetchActiveUserSession();
        setUserInfo(activeUser);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await fetch(`${serverURL}elasticEndpoint`)
          .then((res) => res.json())
          .then((res) => {
            setEndpoint(res.data.endpoint);
            setIndexEnv(res.data.elasticEnvironment);
          });
      } catch (err) {}
    })();
  }, []);

  switch (userInfo.reportLevel) {
    case "gryphon":
      wildCardQuery = (value) => query.wildCardQueryReportLevelGryphon(value);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelGryphon(value);
      initialQuery = () => query.defaultQueryReportLevelGryphon();
      break;
    case "client":
      wildCardQuery = (value) => query.wildCardQueryReportLevelClient(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelClient(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelClient(userInfo.reportEntities);
      break;
    case "region":
      wildCardQuery = (value) => query.wildCardQueryReportLevelRegion(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelRegion(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelRegion(userInfo.reportEntities);
      break;
    case "team":
      wildCardQuery = (value) => query.wildCardQueryReportLevelTeam(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelTeam(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelTeam(userInfo.reportEntities);
      break;
    case "office":
      wildCardQuery = (value) => query.wildCardQueryReportLevelOffice(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelOffice(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelOffice(userInfo.reportEntities);
      break;
    case "agent":
      wildCardQuery = (value) => query.wildCardQueryReportLevelAgent(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelAgent(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelAgent(userInfo.reportEntities);
      break;
    default:
      wildCardQuery = (value) => query.wildCardQueryReportLevelNone(value);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelNone(value);
      initialQuery = () => query.defaultQueryReportLevelNone();
      break;
  }

  const getDecimalValue = (n) => {
    n = parseFloat(n).toFixed(2);
    let decimalValue = (n + "")?.split(".")[1]?.slice(0, 2);
    let value = parseInt((decimalValue / 100) * 60);
    if (isNaN(value)) {
      return 0;
    } else {
      return value;
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const countOccurrences = (string, input) => {
    const escapedString = escapeRegExp(string);
    const regex = new RegExp(escapedString, "g");
    const occurrences = (input?.match(regex) || []).length;
    return occurrences;
  };

  return endpoint.length === 0 || indexEnv.length === 0 || userInfo.reportLevel === "" ? (
    <div>
      <br></br>
      <h4>Fetching Data...</h4>
    </div>
  ) : (
    <ReactiveBase className={styles.baseDiv} url={endpoint} app={indexName}>
      <Row gutter={16} className={styles.rowDiv}>
        <Col span={6} style={{ overflow: "auto", maxHeight: "100%" }}>
          <Card
            headStyle={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
            title="Filter Results"
            type="inner"
            bordered={false}
            className={styles.facets}
            style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
          >
            <div>{statsCount} results found</div>
            <Divider className={styles.elasticSearchDivider}></Divider>
            {userInfo.reportLevel === "gryphon" ? (
              <>
                <MultiDropdownList
                  componentId="ClientName"
                  dataField="ClientName"
                  sortBy="asc"
                  showSearch={true}
                  className={styles.dropdownTitle}
                  size={100000}
                  style={{
                    marginBottom: "16px",
                  }}
                  queryFormat="or"
                  title="Client Name"
                  renderItem={(label, count, isSelected) => (
                    <div style={{ width: "100%" }}>
                      {label}
                      <span
                        style={{
                          float: "right",
                          textAlign: "right",
                          overflow: "hidden",
                          whiteSpace: "normal",
                          color: isSelected ? "red" : "dodgerblue",
                        }}
                      >
                        {count}
                      </span>
                    </div>
                  )}
                  renderNoResults={() => (
                    <div>
                      <h5 style={{ fontSize: "initial" }}>Client Name</h5>
                      <br></br>No Results Found
                    </div>
                  )}
                  react={{
                    and: componentIdsSync.filter((item) => item !== "ClientName"),
                  }}
                  URLParams={false}
                  defaultQuery={initialQuery}
                />
                <Divider className={styles.elasticSearchDivider}></Divider>
              </>
            ) : (
              <></>
            )}
            <MultiDropdownList
              componentId="AgentName"
              dataField="AgentName"
              sortBy="asc"
              showSearch={true}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              className={styles.dropdownTitle}
              queryFormat="or"
              title="Agent Name"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Agent Name</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "AgentName"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="CallDisposition"
              dataField="CallDisposition"
              className={styles.dropdownTitle}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              sortBy="asc"
              showSearch={true}
              title="Activity Disposition"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Activity Disposition</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "CallDisposition"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="KPI"
              dataField="sentences.KPI.value.keyword"
              className={styles.dropdownTitle}
              showSearch={true}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              queryFormat="and"
              title="Key Moments"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Key Moments</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "KPI"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="Account"
              dataField="AccountName"
              className={styles.dropdownTitle}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              sortBy="asc"
              showSearch={true}
              title="Account Name"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                      fontSize: isSelected ? "large" : "inherit",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Account Name</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "Account"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="Opportunity"
              dataField="OpportunityName"
              className={styles.dropdownTitle}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              sortBy="asc"
              showSearch={true}
              title="Opportunity Name"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div style={{ marginBottom: 20 }}>
                  <h5 style={{ fontSize: "initial" }}>Opportunity Name</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "Opportunity"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />

            <Accordion
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                position: "absolute",
                width: "100%",
                left: "0px",
                padding: "4px 12px 4px 12px",
                background: "#FAFAFA",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <h5 style={{ fontSize: "16px" }}>Additional Filters</h5>
              </AccordionSummary>
              <Card bordered={false}>
                <AccordionDetails sx={{ padding: 0, marginBottom: 20 }}>
                  <MultiDropdownList
                    componentId="Stage"
                    dataField="OpportunityStage"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    title="Opportunity Stage"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Opportunity Stage</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "Stage"),
                    }}
                    URLParams={false}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="CampaignName"
                    dataField="CampaignName"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Campaign Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Campaign Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "CampaignName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="CallSource"
                    dataField="CallSource"
                    className={styles.dropdownTitle}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    showSearch={true}
                    title="Call Source"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Call Source</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "CallSource"),
                    }}
                    URLParams={false}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="Speaker"
                    dataField="Participants.custom_role"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    title="Speaker"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Speaker</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "Speaker"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="ParticipantName"
                    dataField="Participants.name"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    sortBy="asc"
                    title="Participant Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Participant Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "ParticipantName"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="HostName"
                    dataField="HostName"
                    className={styles.dropdownTitle}
                    sortBy="asc"
                    showSearch={true}
                    title="Host Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Host Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "HostName"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="Topic"
                    dataField="Topic"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    title="Meeting Subject"
                    sortBy="asc"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Meeting Subject</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "Topic"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="OfficeName"
                    dataField="officeName"
                    showSearch={true}
                    className={styles.dropdownTitle}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Office Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Office Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "OfficeName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="RegionName"
                    dataField="regionName"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Region Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Region Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "RegionName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="TeamName"
                    dataField="teamName"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Team Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Team Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "TeamName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  {statsCount === 0 ? null : (
                    <>
                      <DynamicRangeSlider
                        componentId="Duration"
                        dataField="Duration"
                        className={styles.rangeSlider}
                        title="Conversation Length"
                        rangeLabels={(min, max) => ({
                          start: min + " Min",
                          end: max + " Min",
                        })}
                        showHistogram={true}
                        showFilter={true}
                        tooltipTrigger="always"
                        renderTooltipData={(data) => (
                          <div
                            style={{
                              backgroundColor: "#1971C2",
                              borderRadius: "50%",
                              padding: 3,
                              width: 30,
                            }}
                          >
                            {data}
                          </div>
                        )}
                      />
                      <Divider className={styles.elasticSearchDivider}></Divider>
                      <DynamicRangeSlider
                        componentId="Talktime"
                        dataField="Participants.speaker_metrics.speakDuration"
                        title="Talktime"
                        className={styles.rangeSlider}
                        showHistogram={true}
                        rangeLabels={(min, max) => ({
                          start: min + " Min",
                          end: max + " Min",
                        })}
                        tooltipTrigger="always"
                        renderTooltipData={(data) => (
                          <div
                            style={{
                              backgroundColor: "#1971C2",
                              borderRadius: "50%",
                              padding: 3,
                              width: 30,
                            }}
                          >
                            {data}
                          </div>
                        )}
                      />
                      <Divider className={styles.elasticSearchDivider}></Divider>
                      <RangeSlider
                        componentId="SpeakPercent"
                        dataField="Participants.speaker_metrics.speakPercent"
                        title="Speak Percent"
                        className={styles.rangeSlider}
                        showHistogram={true}
                        range={{
                          start: 0,
                          end: 100,
                        }}
                        rangeLabels={{
                          start: "0%",
                          end: "100%",
                        }}
                        tooltipTrigger="always"
                        renderTooltipData={(data) => (
                          <div
                            style={{
                              backgroundColor: "#1971C2",
                              borderRadius: "50%",
                              padding: 3,
                              width: 30,
                            }}
                          >
                            {data}
                          </div>
                        )}
                      />
                    </>
                  )}
                </AccordionDetails>
              </Card>
            </Accordion>
          </Card>
        </Col>
        <Col span={18} style={{ height: "inherit" }}>
          <Card bodyStyle={{ height: "inherit" }} style={{ height: "inherit", borderRadius: "10px" }}>
            <Row style={{ height: "50px", display: "block" }}>
              <DataSearch
                dataField={["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"]}
                componentId="Search"
                className={styles.searchBar}
                style={{ all: "unset" }}
                URLParams={false}
                highlight={true}
                customHighlight={() => ({
                  highlight: {
                    pre_tags: ["<mark>"],
                    post_tags: ["</mark>"],
                    fields: {
                      Description: {},
                      "sentences.Content": {},
                      "Participants.email": {},
                      DialedNumber: {},
                      Notes: {},
                    },
                    number_of_fragments: 0,
                  },
                })}
                autosuggest={false}
                debounce={100}
                onValueChange={(value) => {
                  setUserInput(value);
                }}
                customQuery={changeQuery ? exactMatchQuery : wildCardQuery}
                searchOperators={true}
                placeholder="Search"
                renderError={(error) => (
                  <div>
                    Something went wrong!
                    <br />
                    Error details
                    <br />
                    {error}
                  </div>
                )}
                react={{
                  and: componentIdsSync.filter((item) => item !== "Search"),
                }}
              />
              <ReactiveComponent
                dataField="StartTimestamp"
                componentId="DateRange"
                defaultQuery={initialQuery}
                URLParams={false}
                filterLabel="Date"
                showFilter={true}
                react={{
                  and: componentIdsSync.filter((item) => item !== "DateRange"),
                }}
                render={({ setQuery }) => (
                  <ESDateRange
                    setQuery={setQuery}
                    refreshDate={refreshDate}
                    defaultFromDate={defaultFromDate}
                    defaultToDate={defaultToDate}
                    selectedFromDate={selectedFromDate}
                    selectedToDate={selectedToDate}
                    setSelectedFromDate={setSelectedFromDate}
                    setSelectedToDate={setSelectedToDate}
                  />
                )}
              />
            </Row>
            <Row style={{ height: "50px" }}>
              <Col span="22">
                <Box className="search-wrapper">
                  <Box className="search-button-wrapper">
                    <Switch
                      checked={changeQuery}
                      onClick={() => setChangeQuery(!changeQuery)}
                      className={styles.searchSwitch}
                      style={{ backgroundColor: changeQuery ? "#1976d2" : "" }}
                    />
                    <BootstrapTooltip title="Only show results matching the exact phrase" placement="top" arrow>
                      <h6 className="exact-match-keyword">Exact text match</h6>
                    </BootstrapTooltip>
                  </Box>
                </Box>
              </Col>
            </Row>
            <Row style={{ maxHeight: "50px" }}>
              <Col span="22">
                <SelectedFilters
                  onChange={(values) => {
                    if (values) {
                      setFilterBool(false);
                      for (const val in values) {
                        if (
                          values[val]?.value !== 0 &&
                          values[val]?.value !== null &&
                          values[val]?.value !== "" &&
                          values[val]?.value?.length !== 0
                        ) {
                          setFilterBool(true);
                        }
                      }
                    }
                  }}
                  className={styles.chips}
                  onClear={(component, values) => {
                    if (component === "DateRange" || component === null) {
                      setSelectedFromDate(defaultFromDate);
                      setSelectedToDate(defaultToDate);
                      setRefreshDate(!refreshDate);
                    }
                    if (values && Object.keys(values).length === 3) {
                      setFilterBool(false);
                    }
                  }}
                />
              </Col>
            </Row>

            <ReactiveList
              componentId="resultCards"
              dataField="StartTimestamp"
              style={{ height: filterBool ? "calc(98% - 100px)" : "calc(98% - 75px)" }}
              sortBy="desc"
              pagination={true}
              size={10}
              paginationAt="bottom"
              showResultStats={true}
              URLParams={false}
              renderNoResults={() => {
                setStatsCount(0);
                return <span>No Results Found</span>;
              }}
              renderResultStats={(stats) => {
                setStatsCount(stats.numberOfResults);
                return `At least ${stats.numberOfResults} results`;
              }}
              defaultQuery={initialQuery}
              react={{
                and: componentIdsSync,
              }}
              render={({ loading, data }) => {
                if (loading) {
                  return (
                    <Box>
                      <LinearProgress color="primary" />
                    </Box>
                  );
                }
                return (
                  <ReactiveList.ResultCardsWrapper id="card" style={{ marginBottom: "28px", overflow: "auto", height: "inherit" }}>
                    {data.map((item, index) => (
                      <div key={item._id} className={styles.result_card}>
                        <Row>
                          <Col span={1} style={{ marginTop: "6px" }}>
                            {item.CallSource === "TelephonyAudio" ? (
                              <img className={styles.audioIcon} alt="Audio call" style={{ borderRadius: "20%" }}></img>
                            ) : (
                              <img
                                className={styles.videoIcon}
                                alt="Video call"
                                style={{ borderRadius: "20%", background: "rgba(28, 117, 188, 0.2)" }}
                              ></img>
                            )}
                          </Col>
                          <Col span={11} style={{ display: "grid", alignContent: "flex-start" }}>
                            <div style={{ color: "#000", width: "inherit", padding: "0px", height: "24px" }}>
                              <div style={{ lineHeight: "24px" }}>
                                {item?.AgentName || item?.ClientName || item?.DialedNumber ? (
                                  <>
                                    {item?.AgentName ? (
                                      <>
                                        <b>{item?.AgentName}</b> called{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {constants.formatPhoneNumber(item?.DialedNumber)?.length === 1 ? (
                                      <React.Fragment>
                                        <b>{item.ClientName?.length > 40 ? item.ClientName.substr(0, 40) + "..." : item.ClientName}</b>
                                      </React.Fragment>
                                    ) : (
                                      <b>{item.DialedNumber ? constants.formatPhoneNumber(item.DialedNumber) : ""}</b>
                                    )}
                                  </>
                                ) : (
                                  <b>{"Call"}</b>
                                )}
                              </div>
                            </div>
                            <div style={{ color: "#000" }}>
                              <div>
                                {item.CallDisposition ? (
                                  <>
                                    Call outcome was <b>{item.CallDisposition}</b> <br />
                                  </>
                                ) : null}
                              </div>
                              <div>
                                <span>
                                  {constants.formatISOTimeToET(item?.StartTimestamp)} {"  "}
                                </span>
                                <span style={{ marginLeft: "6px" }}>
                                  {parseInt(item?.Duration)}m {getDecimalValue(item?.Duration)}s
                                </span>
                              </div>
                            </div>

                            {item.Description || item.AccountName || item.Notes ? (
                              <div style={{ minHeight: "36.5px", width: "100%" }}>
                                <div
                                  className={styles.moreDetails}
                                  style={{ color: "#40a9ff", display: "inline-block", fontSize: "15px" }}
                                  onClick={() => {
                                    setOpenDetails(index === activeId ? !openDetails : true);
                                    setActiveid(index);
                                  }}
                                >
                                  More Details{" "}
                                  <img
                                    alt=""
                                    src={activeId === index && openDetails ? lessIcon : moreIcon}
                                    style={{ height: "4px", width: "8px", marginLeft: "5px" }}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </Col>
                          <Col span={1}>
                            <Divider type="vertical" style={{ height: "100%", backgroundColor: "#E2E2E2" }} />
                          </Col>
                          <Col span={11} style={{ display: "grid", alignContent: "space-between" }}>
                            <ResultCard.Title style={{ marginBottom: "4px", color: "#000", width: "inherit", padding: "0px" }}>
                              <div className={styles.AgentName}>
                                {item?.AgentName?.length > 0 && item?.AgentName ? (
                                  <React.Fragment>{item?.AgentName}</React.Fragment>
                                ) : (
                                  <React.Fragment>Host</React.Fragment>
                                )}
                                <BootstrapTooltip
                                  title={
                                    <div>
                                      Call Length: {parseInt(item?.Duration)}m {getDecimalValue(item?.Duration)}
                                      s
                                      <br />
                                      Talk Time:{" "}
                                      {item?.Participants?.length > 0 && item?.Participants[0]?.speaker_metrics[0]?.speakDuration ? (
                                        <div style={{ display: "inline-flex" }}>
                                          {parseInt(item?.Participants[0]?.speaker_metrics[0]?.speakDuration)}m{" "}
                                          {getDecimalValue(item?.Participants[0]?.speaker_metrics[0]?.speakDuration)}s
                                        </div>
                                      ) : (
                                        <React.Fragment>0m 0s</React.Fragment>
                                      )}
                                      {item?.Participants?.length > 0 && item?.Participants[0]?.speaker_metrics[0]?.speakPercent ? (
                                        <div style={{ display: "inline-flex", marginLeft: 5 }}>
                                          ({item?.Participants[0]?.speaker_metrics[0]?.speakPercent}%)
                                        </div>
                                      ) : (
                                        <React.Fragment></React.Fragment>
                                      )}
                                    </div>
                                  }
                                  placement="right-start"
                                  arrow
                                >
                                  <div>
                                    <TimerOutlinedIcon className={styles.timerIcon} />
                                  </div>
                                </BootstrapTooltip>
                                {item?.Participants?.length > 0 && item?.Participants[0]?.speaker_metrics[0]?.speakPercent ? (
                                  <div style={{ display: "inline-flex", color: "#2779C5" }}>
                                    ({item?.Participants[0]?.speaker_metrics[0]?.speakPercent}%)
                                  </div>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                              </div>
                            </ResultCard.Title>
                            <ResultCard.Description>
                              {userInput?.length === 0 ? (
                                <div
                                  style={{
                                    highlight: "#53C8FF",
                                    overflow: "hidden",
                                    fontSize: "15px",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: "2",
                                    display: "-webkit-box",
                                    minHeight: "auto",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.sentences[0]?.Content,
                                  }}
                                />
                              ) : item.Description === null ? (
                                <div>
                                  <div
                                    style={{
                                      maxHeight: "80px",
                                      highlight: "#53C8FF",
                                      fontSize: "15px",
                                      minHeight: "auto",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item["sentences.Content"]?.length > 220
                                          ? item["sentences.Content"].substr(0, 220) + "...."
                                          : item["sentences.Content"],
                                    }}
                                  />
                                  {countOccurrences(userInput, item["sentences.Content"]) > 1 ? (
                                    <p>+{countOccurrences(userInput, item["sentences.Content"])} occurences</p>
                                  ) : null}
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      maxHeight: "80px",
                                      highlight: "#53C8FF",
                                      fontSize: "15px",
                                      minHeight: "auto",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item["sentences.Content"]?.length > 220
                                          ? item["sentences.Content"].substr(0, 220) + "...."
                                          : item["sentences.Content"],
                                    }}
                                  />
                                </div>
                              )}
                            </ResultCard.Description>
                            <div style={{ display: "inline-flex", width: "100%" }}>
                              {countOccurrences(userInput, item["sentences.Content"]) > 1 ? (
                                <div style={{ display: "grid", alignItems: "center", width: "50%", color: "#6A707E" }}>
                                  {`+${countOccurrences(userInput, item["sentences.Content"])}`} occurences
                                </div>
                              ) : null}
                              <div className={styles.openInPlayer}>
                                <StyledButton href={`${item._id}${conversationRedirect}`} target="_blank" startIcon={<PlayArrowRoundedIcon />}>
                                  Open in player
                                </StyledButton>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {openDetails && index === activeId ? (
                          <>
                            <Row>
                              <Col span={1} />
                              <Col span={23}>
                                <Divider style={{ backgroundColor: "#E2E2E2", marginBottom: "10px", marginTop: "10px" }} />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={1} />
                              {item.AccountName || item.Description ? (
                                <Col span={10}>
                                  <div className={styles.detailTiltles}>Account Name / Activity Contact</div>
                                  <div className={styles.descriptionBox}>
                                    {item.AccountName}
                                    <br />
                                    {item?.Description}
                                  </div>
                                </Col>
                              ) : null}
                              {item?.Notes ? (
                                <>
                                  {item?.AccountName && item?.Notes ? (
                                    <>
                                      <Col span={1}>
                                        <Divider type="vertical" style={{ height: "90%", backgroundColor: "#E2E2E2", marginTop: "4px" }} />
                                      </Col>
                                    </>
                                  ) : undefined}
                                  <Col span={11}>
                                    <div>
                                      <div className={styles.notesTitle}>Notes </div>
                                      <div
                                        className={styles.descriptionBox}
                                        dangerouslySetInnerHTML={{
                                          __html: item?.Notes,
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </>
                              ) : null}
                            </Row>
                          </>
                        ) : null}
                      </div>
                    ))}
                  </ReactiveList.ResultCardsWrapper>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
    </ReactiveBase>
  );
}

const StyledButton = withStyles({
  root: {
    textTransform: "none !important",
    fontWeight: "400 !important",
    "&:hover": {
      borderRadius: "8px",
      color: "#1976d2",
    },
  },
})(Button);
