// This file contains different elasticsearch queries
// We will switch the queries based on a type of user logged in

// Case 1: RL is none
export const wildCardQueryReportLevelNone = (value) => {
  if (value) {
    return {
      query: {
        match_none: {},
      },
    };
  } else {
    return {
      query: {
        match_none: {},
      },
    };
  }
};
export const exactMatchQueryReportLevelNone = (value) => {
  if (value) {
    return {
      query: {
        match_none: {},
      },
    };
  } else {
    return {
      query: {
        match_none: {},
      },
    };
  }
};
export const defaultQueryReportLevelNone = () => {
  return {
    query: {
      match_none: {},
    },
  };
};

// Case 2: RL is gryphon
export const wildCardQueryReportLevelGryphon = (value) => {
  if (value) {
    return {
      query: {
        query_string: {
          query: value,
          fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
        },
      },
    };
  } else {
    return {
      query: {
        match_all: {},
      },
    };
  }
};
export const exactMatchQueryReportLevelGryphon = (value) => {
  if (value) {
    return {
      query: {
        multi_match: {
          query: value,
          type: "phrase",
          fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
        },
      },
    };
  } else {
    return {
      query: {
        match_all: {},
      },
    };
  }
};

export const defaultQueryReportLevelGryphon = () => {
  return {
    query: {
      match_all: {},
    },
  };
};

// Case 3: RL is client
export const wildCardQueryReportLevelClient = (value, clientkey) => {
  if (value) {
    return {
      query: {
        bool: {
          must: {
            query_string: {
              query: value,
              fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
            },
          },
          filter: {
            term: {
              clientKey: clientkey,
            },
          },
        },
      },
    };
  } else {
    return {
      query: {
        bool: {
          must: {
            match_all: {},
          },
          filter: {
            term: {
              clientKey: clientkey,
            },
          },
        },
      },
    };
  }
};
export const exactMatchQueryReportLevelClient = (value, clientkey) => {
  if (value) {
    return {
      query: {
        bool: {
          must: {
            multi_match: {
              query: value,
              type: "phrase",
              fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
            },
          },
          filter: {
            term: {
              clientKey: clientkey,
            },
          },
        },
      },
    };
  } else {
    return {
      query: {
        bool: {
          must: {
            match_all: {},
          },
          filter: {
            term: {
              clientKey: clientkey,
            },
          },
        },
      },
    };
  }
};
export const defaultQueryReportLevelClient = (clientkey) => {
  return {
    query: {
      term: {
        clientKey: clientkey,
      },
    },
  };
};

// Case 4: RL is region
export const wildCardQueryReportLevelRegion = (value, regionkey) => {
  if (regionkey.includes(",")) {
    regionkey = regionkey.split(",");
  }
  if (Array.isArray(regionkey)) {
    //if regionKey is a list then we use terms query
    if (value) {
      return {
        query: {
          bool: {
            must: {
              query_string: {
                query: value,
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              terms: {
                //this is terms query
                regionKey: regionkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              terms: {
                regionKey: regionkey,
              },
            },
          },
        },
      };
    }
  } else {
    //otherwise we use term query
    if (value) {
      return {
        query: {
          bool: {
            must: {
              query_string: {
                query: value,
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              term: {
                //this is term query
                regionKey: regionkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              term: {
                regionKey: regionkey,
              },
            },
          },
        },
      };
    }
  }
};
export const exactMatchQueryReportLevelRegion = (value, regionkey) => {
  if (regionkey.includes(",")) {
    regionkey = regionkey.split(",");
  }
  if (Array.isArray(regionkey)) {
    //if regionKey is a list then we use terms query
    if (value) {
      return {
        query: {
          bool: {
            must: {
              multi_match: {
                query: value,
                type: "phrase",
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              terms: {
                regionKey: regionkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              terms: {
                regionKey: regionkey,
              },
            },
          },
        },
      };
    }
  } else {
    if (value) {
      return {
        query: {
          bool: {
            must: {
              multi_match: {
                query: value,
                type: "phrase",
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              term: {
                regionKey: regionkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              term: {
                regionKey: regionkey,
              },
            },
          },
        },
      };
    }
  }
};
export const defaultQueryReportLevelRegion = (regionkey) => {
  if (regionkey.includes(",")) {
    regionkey = regionkey.split(",");
  }
  if (Array.isArray(regionkey)) {
    return {
      query: {
        terms: {
          regionKey: regionkey,
        },
      },
    };
  } else {
    return {
      query: {
        term: {
          regionKey: regionkey,
        },
      },
    };
  }
};

// Case 5: RL is team
export const wildCardQueryReportLevelTeam = (value, teamkey) => {
  if (teamkey.includes(",")) {
    teamkey = teamkey.split(",");
  }
  if (Array.isArray(teamkey)) {
    //if regionKey is a list then we use terms query
    if (value) {
      return {
        query: {
          bool: {
            must: {
              query_string: {
                query: value,
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              terms: {
                //this is terms query
                teamKey: teamkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              terms: {
                teamKey: teamkey,
              },
            },
          },
        },
      };
    }
  } else {
    //otherwise we use term query
    if (value) {
      return {
        query: {
          bool: {
            must: {
              query_string: {
                query: value,
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              term: {
                //this is term query
                teamKey: teamkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              term: {
                teamKey: teamkey,
              },
            },
          },
        },
      };
    }
  }
};
export const exactMatchQueryReportLevelTeam = (value, teamkey) => {
  if (teamkey.includes(",")) {
    teamkey = teamkey.split(",");
  }
  if (Array.isArray(teamkey)) {
    //if regionKey is a list then we use terms query
    if (value) {
      return {
        query: {
          bool: {
            must: {
              multi_match: {
                query: value,
                type: "phrase",
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              terms: {
                teamKey: teamkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              terms: {
                teamKey: teamkey,
              },
            },
          },
        },
      };
    }
  } else {
    if (value) {
      return {
        query: {
          bool: {
            must: {
              multi_match: {
                query: value,
                type: "phrase",
                fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
              },
            },
            filter: {
              term: {
                teamKey: teamkey,
              },
            },
          },
        },
      };
    } else {
      return {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              term: {
                teamKey: teamkey,
              },
            },
          },
        },
      };
    }
  }
};
export const defaultQueryReportLevelTeam = (teamkey) => {
  if (teamkey.includes(",")) {
    teamkey = teamkey.split(",");
  }
  if (Array.isArray(teamkey)) {
    return {
      query: {
        terms: {
          teamKey: teamkey,
        },
      },
    };
  } else {
    return {
      query: {
        term: {
          teamKey: teamkey,
        },
      },
    };
  }
};

// Case 6: RL is office
export const wildCardQueryReportLevelOffice = (value, officekey) => {
  if (value) {
    return {
      query: {
        bool: {
          must: {
            query_string: {
              query: value,
              fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
            },
          },
          filter: {
            term: {
              officeKey: officekey,
            },
          },
        },
      },
    };
  } else {
    return {
      query: {
        bool: {
          must: {
            match_all: {},
          },
          filter: {
            term: {
              officeKey: officekey,
            },
          },
        },
      },
    };
  }
};
export const exactMatchQueryReportLevelOffice = (value, officekey) => {
  if (value) {
    return {
      query: {
        bool: {
          must: {
            multi_match: {
              query: value,
              type: "phrase",
              fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
            },
          },
          filter: {
            term: {
              officeKey: officekey,
            },
          },
        },
      },
    };
  } else {
    return {
      query: {
        bool: {
          must: {
            match_all: {},
          },
          filter: {
            term: {
              officeKey: officekey,
            },
          },
        },
      },
    };
  }
};
export const defaultQueryReportLevelOffice = (officekey) => {
  return {
    query: {
      term: {
        officeKey: officekey,
      },
    },
  };
};

// Case 7: RL is agent
export const wildCardQueryReportLevelAgent = (value, userKey) => {
  if (value) {
    return {
      query: {
        bool: {
          must: {
            query_string: {
              query: value,
              fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
            },
          },
          filter: {
            term: {
              call_userKey: userKey,
            },
          },
        },
      },
    };
  } else {
    return {
      query: {
        bool: {
          must: {
            match_all: {},
          },
          filter: {
            term: {
              call_userKey: userKey,
            },
          },
        },
      },
    };
  }
};
export const exactMatchQueryReportLevelAgent = (value, userKey) => {
  if (value) {
    return {
      query: {
        bool: {
          must: {
            multi_match: {
              query: value,
              type: "phrase",
              fields: ["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "Participants.email", "DialedNumber"],
            },
          },
          filter: {
            term: {
              call_userKey: userKey,
            },
          },
        },
      },
    };
  } else {
    return {
      query: {
        bool: {
          must: {
            match_all: {},
          },
          filter: {
            term: {
              call_userKey: userKey,
            },
          },
        },
      },
    };
  }
};
export const defaultQueryReportLevelAgent = (userKey) => {
  return {
    query: {
      bool: {
        must: {
          match_all: {},
        },
        filter: {
          term: {
            call_userKey: userKey,
          },
        },
      },
    },
  };
};

// export const getRangeFilterQuery = (reportLevel, value) => {
//   let rangeFilterQuery;
//   if (typeof value !== "number" && value.includes(",")) {
//     value = value.split(",");
//   }
//   switch (reportLevel) {
//     case "gryphon":
//       rangeFilterQuery = { match_all: {} };
//       break;
//     case "client":
//       rangeFilterQuery = {
//         bool: {
//           filter: [
//             {
//               term: {
//                 clientKey: value,
//               },
//             },
//           ],
//         },
//       };
//       break;
//     case "region":
//       rangeFilterQuery = {
//         bool: {
//           filter: [
//             {
//               term: {
//                 regionKey: value,
//               },
//             },
//           ],
//         },
//       };
//       break;
//     case "team":
//       rangeFilterQuery = {
//         bool: {
//           filter: [
//             {
//               term: {
//                 teamKey: value,
//               },
//             },
//           ],
//         },
//       };
//       break;
//     case "office":
//       rangeFilterQuery = {
//         bool: {
//           filter: [
//             {
//               term: {
//                 officeKey: value,
//               },
//             },
//           ],
//         },
//       };
//       break;
//     case "agent":
//       rangeFilterQuery = {
//         bool: {
//           filter: [
//             {
//               term: {
//                 call_userKey: value,
//               },
//             },
//           ],
//         },
//       };
//       break;
//     default:
//       rangeFilterQuery = { match_none: {} };
//       break;
//   }
//   return rangeFilterQuery;
// };
